if('serviceWorker' in navigator) {
	if (navigator.serviceWorker.controller) {
		// console.log('Active service worker found, no need to register')
	} else {
		navigator.serviceWorker.register('/sw.js').then(function(reg) {
			// регистрация сработала
			// console.log('Registration succeeded. Scope is ' + reg.scope);
		}).catch(function(error) {
			// регистрация прошла неудачно
			// console.log('Registration failed with ' + error);
		});
	};
  }

function scrollToElement(selector, duration) {
	if ($(selector).length == 0) return;
	var shift = 0;
	if ($("header .header").css("position") == 'fixed') shift = $("header .header").height();
	$(document).scrollTo($(selector).offset().top - shift, duration);
}

var dragdrop_cancel = false;




$(document).ready(function () {
	//
	// Counter
	//
	$('.js_counter').each(function () {
		$(this).counterUp({
			delay: $(this).attr('data-delay') ? $(this).attr('data-delay') : '100',
			time: $(this).attr('data-time') ? $(this).attr('data-time') : '1200'
		});
	});

	//
	// WOW
	//
	wow = new WOW().init();

	//
	// Statbar position changing
	//
	$('.js_statbar').each(function () {
		$(window).on("resize scroll", function () {
			if ($(document).scrollTop() + window.innerHeight < $('.js_statbar').offset().top + $('.js_statbar').height()) {
				$('.statbar-inner').css('position', 'fixed');
			} else {
				$('.statbar-inner').css('position', 'static');
			}
		});
	});

	//
	// Swidget desc. toggle
	//
	$('.js_swidget').on('click', function() {
		var sd = $(this).hasClass('showdesc');
		$('.js_swidget[data-group='+$(this).attr("data-group")+']').removeClass('showdesc');
		if (!sd) $(this).addClass('showdesc');
	});


	//
	// Owl Carousel
	//
	$(".js_owl").each(function () {
		switch ($(this).attr("data-type")) {
			case "hero":
				$(this).owlCarousel({
					items: 1,
					autoplay: true,
					smartSpeed: 500,
					dots: false,
					loop: true,
					animateIn: 'fadeInUp',
					animateOut: 'fadeOut'
				});
				break;
			case "related-topics": 
			case "featured-topics":
				$(this).owlCarousel({
					dotsEach: 1,
					
					// autoHeight: true,
					// dots: false,
					// loop: true,
					// margin: 15,
					responsiveClass: true,
					responsive:{
						0:{
							items: 1,
							stagePadding: 15,
							// center:true,
							// autoWidth:true,
						},
						576:{
							items: 2,
							stagePadding: 15,
							// nav: true,
							// center:true,
							// autoWidth:true,
						},
						768:{
							items: 2,
							stagePadding: 30,
							// nav: true,
							// center:true,
							// autoWidth:true,
						},
						992: {
							items: 3,
							nav: true,
							// autoWidth:true,
							// center:true,
						}
					},
  					// onInitialized: function() { $('.owl-nav').appendTo('.owl-stage-outer'); }, // to center vertically inside .owl-stage-outer
					navElement: 'div',
					navText: ['<svg viewBox="0 0 24 24"><path d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z" /></svg>', '<svg viewBox="0 0 24 24"><path d="M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z" /></svg>']
				});
				break;
			// 
			// temp. disabled	
			//
			// case "screenshots":
			// 	$(this).owlCarousel({
			// 		autoWidth: true,
			// 		center: true,
			// 		loop: true,
			// 	});
			// 	break;
			// case "review":
			// 	$(this).owlCarousel({
			// 		items: 1,
			// 		center: true,
			// 		autoHeight: true,
			// 		nav: true,
			// 		navElement: 'div',
			// 		navText: ['<svg viewBox="0 0 24 24"><path d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z" /></svg>', '<svg viewBox="0 0 24 24"><path d="M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z" /></svg>']
			// 	});
			// 	break;
			default:
				$(this).owlCarousel();
				break;
		}
	});

	//
	// Circliful chart
	//
	$('.circliful-chart').circliful({}, function () {
		alert('done !');
	});

	//
	// Peity
	//
	$(".js_pie").each(function () {
		switch ($(this).attr("data-type")) {
			case "statbar":
				$(this).peity("pie", {
					fill: [$(this).attr("data-color"), "white"],
					radius: 6,
				});
				break;
			case "e-card":
				$(this).peity("donut", {
					fill: [$(this).attr("data-color"), "#8189a933"],
				});
				break;
			default:
				$(this).peity("pie");
				break;
		}
	});


	// Fade-in for exercise cards
	$(function () {
		$("#card-fade").removeClass("fade-out");
	});

	//
	// Fixed height for exercise 2
	//
	$(function () {
		$(window).on("resize", function () {
			$(".js_fixheight")
				.css("height", "")
				.css("height", $(".js_fixheight").height());
		}).resize();
	});

	//
	// Fixed width for exercise 1
	//
	$(function () {
		$(window).on("resize", function () {
			$(".js_fixwidth")
				.css("width", "")
				.css("width", $(".js_fixwidth").width());
		}).resize();
	});

	//TEMPORARILY BLOCKED 2018.08.02 $('[data-toggle="tooltip"]').tooltip();
	if ('ontouchstart' in window) { //touch events
		$('.js_substitute').each(function () {
			$(this).on("click", function () {
				var $form = $(this).closest('form');
				$form.find(".av").html("...");
				for (var i in answers[$(this).index()]) {
					$form.find(".av:eq(" + i + ")").html(answers[$(this).index()][i]);
				}
			});
		});
	} else {
		$('.js_substitute').each(function () {
			$(this).hover(function () {
				var $form = $(this).closest('form');
				for (var i in answers[$(this).index()]) {
					$form.find(".av:eq(" + i + ")").html(answers[$(this).index()][i]);
				}
			}, function () {
				var $form = $(this).closest('form');
				$form.find(".av").html("...");
			});
		});
	}

	$('.js_autosize').each(function () {
		$(this)
			.on("input", function () {
				$(this).after('<span id="input_probe">' + $(this).val() + '</span>');
				$(this).css("width", $('#input_probe').width() + 4);
				//$('#input_probe').width();
				$('#input_probe').remove();
				//$(this).css("width", "50px");
				$(".js_inputsrequired").trigger("check");
			})
			.on("click", function () {
				if (!($(this).hasClass("focus"))) { $(this)[0].select(); }
			})
			.on("focusin", function () {
				$(this).animate({ opacity: 1 }, 100, function () { $(this).addClass("focus"); });
			})
			.on("focusout", function () {
				$(this).stop().clearQueue().removeClass("focus");
			});
	});

	$(".js_inputsrequired").on("check", function () {

		var submit = true;
		$(this).find("input, select").each(function () {
			if ($(this).val() == "" || $(this).val() == null) submit = false;
			console.log($(this).val(), submit);
		});
		if (submit) {
			$(this).find("input[type=submit], button[type=submit]").removeAttr("disabled").addClass("btn-primary");
		} else {
			$(this).find("input[type=submit], button[type=submit]").attr("disabled", true).removeClass("btn-primary");
		}

	}).each(function () {
		$(this).find("input:first").focus().select();
	});

	$(".exercise .js_select2").select2({
		minimumResultsForSearch: 100
	}).each(function () {
		$(this).find("option").removeAttr("disabled");
	});

	$(".js_select2.required").each(function () {
		$(this).change(function () {
			$(".js_inputsrequired").trigger("check");
		});
	});

	$(".js_langSelect").each(function () {
		$(this).select2({
			minimumResultsForSearch: -1,
			dropdownParent: $(this).parent()
		}).on('change', function (e) {
			document.location = $(this).select2('val');
		});
	});

	$(".js_dragdrop_target").each(function () {
		$(this).sortable({
			items: ".dd-item",
			connectWith: ".js_dragdrop_target[data-target=" + $(this).attr("data-target") + "], .js_dragdrop_base[data-target=" + $(this).attr("data-target") + "]",
			placeholder: "ui-sortable-placeholder",
			//tolerance: "pointer",
			distance: 12,
			appendTo: document.body,
			beforeStop: function (event, ui) {
				if (ui.placeholder.parent().find("div").length > 2 && ui.placeholder.parent().hasClass("js_dragdrop_target")) {
					dragdrop_cancel = true;
				}
			},
			stop: function (event, ui) {
				if (dragdrop_cancel) { $(this).sortable('cancel'); dragdrop_cancel = false; }
			}
		});
	});

	$(".js_dragdrop_base").each(function () {
		$(this).sortable({
			items: ".dd-item",
			connectWith: ".js_dragdrop_target[data-target=" + $(this).attr("data-target") + "], .js_dragdrop_base[data-target=" + $(this).attr("data-target") + "]",
			placeholder: "ui-sortable-placeholder",
			//tolerance: "pointer",
			distance: 12,
			appendTo: document.body,
			//start: function( event, ui ) {},
			//sort: function( event, ui ) {},
			//receive: function(ev, ui) {},
			beforeStop: function (event, ui) {
				if (ui.placeholder.parent().find("div").length > 2 && ui.placeholder.parent().hasClass("js_dragdrop_target")) {
					dragdrop_cancel = true;
				}
			},
			stop: function (event, ui) {
				if (dragdrop_cancel) { $(this).sortable('cancel'); dragdrop_cancel = false; }

				var $form = $(this).closest("form");
				var checked = true;
				$form.find(".dd-line_base .dd-items > div").each(function () {
					checked = false;
				});

				if (checked) {
					$form.find("button[type=submit], input[type=submit]").removeAttr("disabled").addClass("btn-primary");
				} else {
					$form.find("button[type=submit], input[type=submit]").attr("disabled", "").removeClass("btn-primary");
				}
			}
		}).find(".dd-item").each(function () {
			$(this).hover(function () {
				$(this).addClass("hover");
			}, function () {
				$(this).removeClass("hover");
			});
		});
	});

	$(".js_dragdrop_form").submit(function () {
		var answer = [];
		$(this).find(".js_dragdrop_target").each(function () {
			var targetArray = [];
			$(this).find(".dd-item").each(function () {
				targetArray.push($(this).attr("id"));
			});
			answer.push(targetArray.join(','));
		});

		$(this).find("#answer").val(answer.join('|'));

		return true;
	});

	$(".js_sortable_base .dd-item").each(function () {
		$(this).click(function () {
			$(this).removeClass("hover");
			if ($(this).parent().hasClass("js_sortable_base")) {
				$(".js_sortable_target[data-target=" + $(this).parent().attr("data-target") + "]:eq(0)").append($(this));
			} else {
				$(".js_sortable_base[data-target=" + $(this).parent().attr("data-target") + "]:eq(0)").append($(this));
			}

			var $form = $(this).closest("form");
			var checked = true;
			$form.find(".dd-line_base .dd-items > div").each(function () {
				checked = false;
			});

			if (checked) {
				$form.find("button[type=submit], input[type=submit]").removeAttr("disabled").addClass("btn-primary");
			} else {
				$form.find("button[type=submit], input[type=submit]").attr("disabled", "").removeClass("btn-primary");
			}
		}).hover(function () {
			$(this).addClass("hover");
		}, function () {
			$(this).removeClass("hover");
		});
	});

	$(".js_sortable_form").submit(function () {
		var answer = [];
		$(this).find(".js_sortable_target").each(function () {
			if (typeof answer[$(this).attr("data-target")] == "undefined") answer[$(this).attr("data-target")] = [];
			var targetArray = [];
			$(this).find(".dd-item").each(function () {
				targetArray.push($(this).attr("id"));
			});
			answer[$(this).attr("data-target")].push(targetArray.join(','));
		});

		for (i in answer) {
			$(this).find("#answer_" + i).val(answer[i].join('|'));
		}

		return true;
	});

	$(".js_filter").each(function () {
		$(this).find(".input-filter").on("input keyup", { filter: $(this) }, function (e) {
			if ($(this).val().length > 2) {
				var searchwords = e.data.filter.find(".input-filter").val().split(" ");
				var checkwords = function (word, text) {
					var regex = new RegExp(word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
					return !(regex.test(text) === false);
				};
				e.data.filter.find(".input-group-text").addClass("input-group-filtered");
				e.data.filter.find(".input-filter").addClass("input-filter-filtered");
				e.data.filter.find(".input-group-append").show();

				e.data.filter.find(".js_filtering .expl-tree__item")
					.hide()
					.each(function () {
						var expl = $(this).find("a:first");
						var is_contain = true;
						
						for (var i in searchwords) { if(i=="insert") continue; if (!checkwords(searchwords[i], expl.html())) { is_contain = false; break; } }
						if (is_contain) {
							$(this).show();
							expl.addClass("filtered").removeClass("filtered-disabled");
							var $item = $(this);
							while ($item.attr("data-level") > 1 && $item.hasClass("expl-tree__item")) {
								//$item.show();
								if ($item.attr("data-level") != $item.prev().attr("data-level")) {
									$item = $item.prev();
									$item.show();
								} else {
									$item = $item.prev();
								}
							}
						} else {
							expl.addClass("filtered-disabled");
							//if (!($(this).hasClass("expl-tree__item_lvl-1"))) $(this).hide();
						}
					});

				if (e.data.filter.find(".js_filtering .expl-tree__item:visible").length > 0) {
					e.data.filter.find(".js_filtering_noresult").hide();
				} else {
					e.data.filter.find(".js_filtering_noresult").show();
				}
				e.data.filter.find(".js_filtering_clear").show();
			} else {
				e.data.filter.find(".input-group-text").removeClass("input-group-filtered");
				e.data.filter.find(".input-filter").removeClass("input-filter-filtered");
				e.data.filter.find(".input-group-append").hide();

				$(".js_filtering .expl-tree__item")
					.show()
					.each(function () {
						var expl = $(this).find("a:first");
						expl.removeClass("filtered-disabled filtered");
					});

				e.data.filter.find(".js_filtering_clear, .js_filtering_noresult").hide();
			}
		}).submit(function () { return false; });
	});

	$(".js_filtering_clear").click(function () {
		$(this).closest(".js_filter").find(".input-filter").val('').keyup();
		return false;
	});

	$(".js_dashboardNav").each(function () {
		if (document.location.hash) {
			window.scrollTo(0, 0);
			$(this).find(document.location.hash).click();
		}
	});

	$("#expl-panel-show").click(function () {
		$("#on-page-expl").removeClass("expl-panel_hidden");
		$("body").css('overflow', 'hidden');
	});

	$("#expl-panel-hide").click(function () {
		$("#on-page-expl").addClass("expl-panel_hidden");
		$("body").css('overflow', 'auto');
	});

	$("#card-fade").removeClass("fade-out");

	form_validate();
	
	$(".js_disableAutoFill").disableAutoFill({
		randomizeInputName: true,
	    html5FormValidate: true
	});
	
	$(".js_scrollto").click(function(){
		scrollToElement($(this).attr("href"), 1000);
		history.replaceState({}, '', document.location.origin + document.location.pathname + $(this).attr("href"));
		return false;
	});
	
	if (document.location.hash != "") {
		scrollToElement(document.location.hash, 1000);
	}
	
	$(".js_contactUsSubject").each(function () {
		$(this).select2({
			minimumResultsForSearch: -1,
			// dropdownParent: $(this).parent(),
			width: '100%'
		}).on('change', function (e) {
			// document.location = $(this).select2('val');
		});;
	});
	
	$(".js_contactus").click(function(){
		var $modal = $(".modal#contactUsModal");
		var $select = $modal.find("select[name=subject]");
		
		if ($(this).attr("data-value") && $select.length > 0) {
			$select.val($(this).attr("data-value")).trigger('change.select2');
		} else {
			$select.val("1").trigger('change.select2');
		}
		$modal.modal("show");
		
		return false;
	});

});