
// Player for the exercises
(function(){
    $.fn.audioPlayer = function(params) {
        
        var params = $.extend({classPrefix: 'player', classSep: '__', stateSep: '--'}, params),
            cssClass = { 
                controls: 'controls',
                control: 'control',
                btnPlay: 'play-pause',
                btnSlow: 'play-slow-pause',
                btnIcon: 'button-icon',
                progress: 'progress',
                progressBar: 'progress' + params.classSep + 'seek',
                buffer: 'progress' + params.classSep + 'buffer'
            },
            cssMod = { 
                default: params.stateSep + 'default', 
                pressed: params.stateSep + 'pressed' 
            },
            iconSrc = {
                play: "/templates/lin/assets/icons/icons.svg#play",
                playSlow: "/templates/lin/assets/icons/icons.svg#play-slow",
                pause: "/templates/lin/assets/icons/icons.svg#pause"
            };
            for (var el in cssClass) cssClass[el] = params.classPrefix + params.classSep + cssClass[el];
            for (var el in iconSrc) iconSrc[el] = '<use xlink:href="' + iconSrc[el] + '"></use>';
            

        var audio = $(this).get(0),
            wasPlaying = false,
            normalSpeed = true,
            audioSources = {default: audio.getAttribute('data-normal'), slow: audio.getAttribute('data-slow')};
        

        var player = $(`
                <div class="` + params.classPrefix + `">
                    <div class="` + cssClass.controls + `">
                        <button type="button" class="`+ cssClass.control + ` ` + cssClass.btnPlay + `">
                            <svg class="` + cssClass.btnIcon + ` ` + cssClass.btnIcon + cssMod.default + `">` + iconSrc.play + `</svg>
                            <svg class="` + cssClass.btnIcon + ` ` + cssClass.btnIcon + cssMod.pressed + `">` + iconSrc.pause + `</svg>
                        </button>` +
                        (audioSources.slow ? `<button type="button" class="` + cssClass.control + ` ` + cssClass.btnSlow + `">
                            <svg class="` + cssClass.btnIcon + ` ` + cssClass.btnIcon + cssMod.default + `">` + iconSrc.playSlow + `</svg>
                            <svg class="` + cssClass.btnIcon + ` ` + cssClass.btnIcon + cssMod.pressed + `">` + iconSrc.pause + `</svg>
                        </button>` : "") +
                        `<div class="` + cssClass.progress + `">
                            <input type="range" class="` + cssClass.progressBar + `" min="0" max="100" step="0.01" value="0" style="--value: 0%;">
                            <progress class="` + cssClass.buffer + `" min="0" max="100" value="0">% buffered</progress>
                        </div>
                    </div>
                </div>
            `),
            btnPlay = player.find('.' + cssClass.btnPlay).get(0),
            btnSlow = audioSources.slow ? player.find('.' + cssClass.btnSlow).get(0) : false,
            progressBar = player.find('.' + cssClass.progressBar).get(0),
            buffer = player.find('.' + cssClass.buffer).get(0);
            
            player.insertBefore($(this));


        function swithTo(speed) {
            progressReset();
            updateProgressBuffer();
            if (speed == "slow") {
                audio.src = audioSources.slow;
                normalSpeed = false;
            } else {
                audio.src = audioSources.default;
                normalSpeed = true;
            }
        }

        function progressReset() {
            audio.currentTime = 0;
            progressBar.value = 0;
            progressBar.style.setProperty('--value', '0%');
            buffer.value = 0;
        }

        function updateButtons() {
            var pressed = cssClass.control + cssMod.pressed;
            if (audio.paused) {
                btnPlay.classList.remove(pressed);
                if (btnSlow) btnSlow.classList.remove(pressed);
            } else {
                if (normalSpeed) {
                    btnPlay.classList.add(pressed);
                    if (btnSlow) btnSlow.classList.remove(pressed);
                } else {
                    btnPlay.classList.remove(pressed);
                    btnSlow.classList.add(pressed);
                }
            }
        }								

        btnPlay.addEventListener('click', function() {
            if (audio.paused) {
                if (normalSpeed) {
                    audio.play();
                } else {
                    swithTo('normal');
                    audio.play();
                }
            } else {
                if (normalSpeed) {
                    audio.pause();
                } else {
                    audio.pause();
                    swithTo('normal');
                    audio.play();
                }
            }
        });

        if (btnSlow) btnSlow.addEventListener('click', function() {
            if (audio.paused) {
                if (!normalSpeed) {
                    audio.play();
                } else {
                    swithTo('slow');
                    audio.play();
                }
            } else {
                if (!normalSpeed) {
                    audio.pause();
                } else {
                    audio.pause();
                    swithTo('slow');
                    audio.play();
                }
            }
        });				

        audio.addEventListener('play', function () {
            updateButtons();
        });

        audio.addEventListener('pause', function () {
            updateButtons();
        });

        audio.addEventListener('timeupdate', function () {
            var percent = isNaN(audio.duration) ? 0 : audio.currentTime / audio.duration * 100;
            progressBar.value = percent;
            progressBar.style.setProperty('--value', percent + '%');
        });


        progressBar.addEventListener('mousedown', function () {
            if (!audio.paused) {
                wasPlaying = true;
                audio.pause();
            }
        });

        progressBar.addEventListener('input', function () {
            var percent = progressBar.value / progressBar.max;
            audio.currentTime = percent * audio.duration;
        });

        progressBar.addEventListener('mouseup', function () {
            if (wasPlaying) {
                audio.play();
                wasPlaying = false;
            }
        }); 
                        
        function updateProgressBuffer() {
            var interval = setInterval(function () {
                if (audio.buffered.length < 1) return true;
                for (var i = 0; i < audio.buffered.length; i++) {
                    if (audio.buffered.start(audio.buffered.length - 1 - i) <= audio.currentTime) {
                        buffer.value = audio.buffered.end(audio.buffered.length - 1 - i) / audio.duration * 100;
                        if (Math.floor(audio.buffered.end(audio.buffered.length - 1 - i)) >= Math.floor(audio.duration)) clearInterval(interval);
                        break;
                    }
                }
            }, 100);
        };
        updateProgressBuffer();
                
    };
})();

// Player for the theory
(function(){
    $.fn.audioPlayerSimple = function() {
        var audio = this.get(0),
            playButton = audio.nextSibling,
            playIcon = $('<svg class="icon-inline icon-inline-player player-simple-icon"><use xlink:href="/templates/lin/assets/icons/icons.svg#audio"></use></svg>');
     
        playButton.classList.add('player-simple');
        playButton.addEventListener('click', function() {
            // audio.paused ? audio.play() : audio.pause();
            audio.play();
        });
        playButton.prepend((playIcon).get(0));

        audio.addEventListener('play', function () {
            playButton.classList.add('player-simple--playing');
        });

        audio.addEventListener('pause', function () {
            playButton.classList.remove('player-simple--playing');
            audio.currentTime = 0;
        });
    }
})();

// Stop all other player-simple
document.addEventListener('play', function(e){
    var audios = document.getElementsByClassName('js_player-simple');
    for (var i = 0; i < audios.length; i++) if (audios[i] != e.target) {
        audios[i].pause();
        audios[i].currentTime = 0;
    };    
}, true);