$.validator.addMethod(
	"validateidnemail",
	function (obj) {
		if (obj.length == 0) {
			return true;
		} else {
			var re = /^[-\w.]+@([А-яёA-z0-9][-А-яёA-z0-9]+\.)+[А-яёA-z]{2,6}$/;
			return re.test(obj);
		}
	},
	$.validator.messages.validateidnemail
);

$.validator.setDefaults({
	errorClass: "invalid-feedback mb-0",
	highlight: function (element) {
		$(element)
			//.closest(".form-control")
			.addClass("is-invalid")
			.removeClass("is-valid");
	},
	unhighlight: function (element) {
		$(element)
			//.closest("input")
			.removeClass("is-invalid")
			.addClass("is-valid");
	}
});

function form_validate() {
	$("form.validate").each(function() {
		var rules = {
			email: {
				validateidnemail: true
			},
			password: {
				minlength: 6
			},
			confirm_password: {
				minlength: 6,
				equalTo: "#password"
			}
		};
		var messages = {
			email: {
				required: $.validator.messages.emailRequired,
				remote: $.validator.messages.emailTaken
			},
			password: {
				required: $.validator.messages.passwordRequired,
				minlength: $.validator.messages.minLength
			},
			confirm_password: {
				required: $.validator.messages.passwordRequired,
				minlength: $.validator.messages.minLength,
				equalTo: $.validator.messages.equalTo
			}
		};
 
		switch($(this).attr("data-type")) {
			case "contactus":
				$(this).validate({
					submitHandler: function (form) {
						 var data = {};
						 $(form).find("input[type=hidden], input[type=text], input[type=email], input[type=radio]:checked").each(function(){
							 data[$(this).attr("name")] = $(this).val();
						 });
						 $(form).find("select[name], textarea[name]").each(function(){
							 data[$(this).attr("name")] = $(this).val();
						 });
						 data['task'] = 'callback';
						 // $(form).addClass("loading"); //троббер
						 $(form).find("button[type=submit]").hide();
						 $.ajax({
							 type: "POST",
							 url: "",
							 data: data,
							 context: form,
							 success: function(msg) {
								 $(this)
								 .removeClass("loading")
								 .replaceWith(lang['contactus_complete']);
							 }
						 });
						return false;
					},
					rules: rules,
					messages: messages
				});
			break;
			case "register":
				var rules = {
					email: {
						validateidnemail: true,
						remote: {
							url: "/signup?notice=ignore",
							type: "post",
							data: {
								email: function() {
									return $("#email").val();
								},
								task: 'checkmail'
							}
						}
					},
					password: {
						minlength: 6
					},
					confirm_password: {
						minlength: 6,
						equalTo: "#password"
					}
				};
				$(this).validate({
					submitHandler: function (form) {
						return true;
					},
					rules: rules,
					messages: messages
				});
			break;
			default:
				$(this).validate({
					submitHandler: function (form) {
						return true;
					},
					rules: rules,
					messages: messages
				});
			break;
		}
	});
 }